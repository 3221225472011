<template>
    <div class="font-weight-thin caption text-center">
        Made with
        <v-icon x-small style="color:#ff3460;">fa-heart</v-icon>
        by <a style="font-weight: 500;" href="http://dataconsulting.mioti.es/">MIOTI Data & AI Services</a>
        &copy; {{current_year}} - {{fullVersion()}}
    </div>

</template>

<script>
import { LAST_VERSION } from '@/variables'

export default {
  name: 'MadeWithLove',
  data () {
    return {
      current_year: new Date().getFullYear()
    }
  },
  methods: {
    getValid (val) {
      if (!val) {
        return ''
      } else if (val === '.') {
        return 'Local Version'
      } else {
        return val
      }
    },
    fullVersion () {
      const lastVersion = this.getValid(LAST_VERSION)
      return lastVersion
    }
  }
}
</script>

<style scoped>

</style>
